/* App.css */

.App {
  text-align: center;
  background-color: #87CEFA;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: Arial, sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-grow: 1;
}

.card {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
  max-width: 500px;
  margin-top: 50px;
}

.card h2 {
  margin-bottom: 20px;
}

.card p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.button-group .btn {
  flex: 1;
  margin: 0 10px;
  font-size: 1em;
  padding: 10px 20px;
}

.stats {
  background-color: #4B70DD;
  color: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  width: 80%;
  max-width: 500px;
  text-align: center;
  position: absolute;
  bottom: 20px;
}

.stats p {
  margin: 0;
} 